var site = site || {};

site.productSort = {
  allowExtendedMPPSort: false,
  prodsByInv: [],
  init: function(productsArray) {
    var that = this;
    var productsArray = productsArray;
    var $sortSelect = $('.js-product-sort');

    //alphabetical names
    var productsArrayNames = _.sortBy(productsArray, function(p) {
      var tempNames = p.PROD_RGN_NAME.toLowerCase();
      //strip out html
      tempNames = tempNames.replace(/<(?:.|\n)*?>/gm, ' ');
      return tempNames;
    });

    //newest - MISC_FLAG new shades (3) and new (1)
    var productsArrayNewest = _.filter(productsArray, function(p){ return p.MISC_FLAG == 1 || p.MISC_FLAG == 3; });
    var productDiff = _.difference(productsArray, productsArrayNewest);
    productsArrayNewest = productsArrayNewest.concat(productDiff);
    //rated - AVERAGE_RATING
    var productsArrayRated = _.sortBy(productsArray, function(p) {
      return -p.AVERAGE_RATING;
    });

    //price high
    var productsArrayPriceHigh = _.sortBy(productsArray, function(p) {
      var skuPrice = _.min(p.skus, function(sku){ return sku.PRICE; });
      skuPrice = skuPrice.PRICE;
      return -skuPrice;
    });

    //price low
    var productsArrayPriceLow = _.sortBy(productsArray, function(p) {
      var skuPrice = _.min(p.skus, function(sku){ return sku.PRICE; });
      skuPrice = skuPrice.PRICE;
      return skuPrice;
    });

    $sortSelect.change(function(event) {
      var $selected = $(this).find('option:selected');
      var dataSort = $selected.attr('data-sort');
      var sortedProducts;
      if (dataSort == 'all') {
        sortedProducts = that.prodsByInv.length ? that.prodsByInv : productsArray;
      } else if (dataSort == 'name') {
        sortedProducts = productsArrayNames;
      } else if (dataSort == 'newest') {
        sortedProducts = productsArrayNewest;
      } else if (dataSort == 'rated') {
        sortedProducts = productsArrayRated;
      } else if (dataSort == 'price-high') {
        sortedProducts = productsArrayPriceHigh;
      } else if (dataSort == 'price-low') {
        sortedProducts = productsArrayPriceLow;
      }
      that.sort(sortedProducts);
    });

    // trigger sort for inventory status
    $(document).on('mpp_product_inventory_status_sort_ready_mobile', function(event, sortedProducts) {
      var sortedProducts;
      var productsArrayNewL2 = [];
      var productIDs = _.pluck(sortedProducts, 'PRODUCT_ID');

      // iterate through productsArray using $.each()
      $.each(productsArray, function(index, product) {
          // iterate through the skus array of each product using $.each()
          $.each(product.skus, function(index, sku) {
              // find the corresponding product in sortedProducts
              const correspondingProduct = sortedProducts.find(sp => sp.PRODUCT_ID === product.PRODUCT_ID);
              if (correspondingProduct) {
                  // find the corresponding SKU in the corresponding product
                  const correspondingSku = correspondingProduct.skus.find(sku2 => sku2.SKU_ID === sku.SKU_ID);
                  // update INVENTORY_STATUS if a matching SKU is found in the corresponding product
                  if (correspondingSku) {
                      sku.INVENTORY_STATUS = correspondingSku.INVENTORY_STATUS;
                  }
              }
          });
      });

      // reorder mpp products to match L2 products order (inventory status)
      $(productIDs).each(function(index, prodID) {
        var prs = _.filter(productsArray, function(p){ return p.PRODUCT_ID == prodID; });
        productsArrayNewL2.push(prs[0]);
      });
      that.sortByInventoryStatus(productsArrayNewL2);
    });

  },
  // multi grid sort and inv status sort
  sortMultiGrid: function(sortedProducts) {
    var that = this;
    var $productContainer = $('.mpp__product-grid');
    if ( ($productContainer.length < 1) || ($productContainer.length === 1) ) {
      return null;
    }
    var $products = $('.product-brief');
    var multiGridMppProductsObj = [];
    var multiGridMppProductsIdsLists = [];
    if ($productContainer.length > 1) {
      $productContainer.each(function(i) {
        var productIdM = $(this).find('.product-brief[data-product-id]');
        multiGridMppProductsObj.push(productIdM);
      });
      // products ids / each grid
      var multiGridMppProductsIds = [];
      $(multiGridMppProductsObj).each(function(i) {
        var gridProductsIds = [];
        $(this).each(function(i) {
          var productIDsMulti = $(this).data('product-id');
          gridProductsIds.push(productIDsMulti);
        });
        multiGridMppProductsIds.push(gridProductsIds);
      });
      // lists
      var productIDsL2 = _.pluck(sortedProducts, 'PRODUCT_ID');
      $.each(multiGridMppProductsIds, function(i, prodIds) {
        var productIDsL2List = _.intersection(productIDsL2, $(this));
        multiGridMppProductsIdsLists.push(productIDsL2List);
      });
    }
    //remove styles on $products
    $products.removeAttr('style');
    // make new list of products (w ids/grid data)
    var productsArrayNew = [];
    $(multiGridMppProductsIdsLists).each(function(index, iProductIdl2){
      var productIDs = iProductIdl2;
      var indexI = index;
      $.each(productIDs, function(i, prodID) {
        var prs = _.filter(multiGridMppProductsObj[indexI], function(p, i) {
          $(p).attr('grid', indexI);
          if ($(p).data('product-id') === prodID) {
            return p;
          }
        });
        productsArrayNew.push(prs[0]);
      });
    });
    // group list by grids
    var prodsSortedGrids = _.groupBy(productsArrayNew, function(p) {
      return $(p).attr('grid');
    });
    // lists to grids
    $.each(prodsSortedGrids, function(i) {
      $productContainer.eq(i).prepend($(this));
    });
    if (that.allowExtendedMPPSort) {
      site.productView.mobileExtendedMppRebuild(sortedProducts);
    } else {
      site.productView.mobileMppRebuild(sortedProducts);
    }
  },

  sortByInventoryStatus: function(sortedProducts){
    var that = this;
    var $productContainer = $('.mpp__product-grid');
    if ($productContainer.length < 1) {
      return null;
    }
    if ($productContainer.length > 1) {
      this.sortMultiGrid(sortedProducts);
      return null;
    }
    var $products = $('.product-brief');
    //strip sorted object for PRODUCT_ID
    var productIDs = _.pluck(sortedProducts, 'PRODUCT_ID');
    var $sortedProducts = [];
    $(productIDs).each(function(index, prodID) {
      $sortedProducts.push($products.filter("[data-product-id=" + prodID +  "]"));
    });

    // apply original tout positions after sorting products
    var toutsPlaceholder = this.getTouts($products);

    //remove styles on $products
    $products.removeAttr('style');
    //move sorted products to top
    $productContainer.empty();
    $productContainer.prepend($sortedProducts);
    //add back in quickshop and update row heights
    if (that.allowExtendedMPPSort) {
      site.productView.mobileExtendedMppRebuild(sortedProducts);
    }
    else {
      site.productView.mobileMppRebuild(sortedProducts);
    }
    this.insertTouts(toutsPlaceholder, $sortedProducts);

  },

  sortSkuGridByInventoryStatus: function(sortedProducts) {
    var $mppExtendedGrids = $('.js-extended-mpp .js-mpp-product-grid');
    var $gridSkuOrder = $('li.js-product-brief[data-sku-id]', $mppExtendedGrids);
    var allProducts = sortedProducts;
    var gridSkuOrderList = [];
    var mppSkusBrief = [];
    var mppSkusBriefList = [];
    var skusBriefSorted = [];
    if (!$(allProducts).length || !$mppExtendedGrids.length) {
      return;
    }
    $.each($gridSkuOrder, function() {
      gridSkuOrderList.push($(this).data('sku-id'));
    });
    $.each($mppExtendedGrids, function(i) {
      $(this).data({'data-grid-index': i});
    });
    $.each(allProducts, function(index, product) {
      var skus = product.skus;
      $.each(skus, function(index, sku) {
        var skuBaseID = sku.SKU_ID;
        skuBaseID = skuBaseID.replace('SKU', '');
        var $sku = $("li[data-sku-id='" + skuBaseID + "']");
        var gridIndex = $sku.parents('.js-mpp-product-grid').data('data-grid-index');
        $sku.data({
          'inventory-status': sku.INVENTORY_STATUS,
          'sku-id': skuBaseID,
          'mpp-grid-index': gridIndex
        });
        if ($sku.length) {
          mppSkusBrief.push($sku);
        }
      });
    });
    // retain template order
    $.each(gridSkuOrderList, function(i, skuId) {
      var gridSkuId = skuId.toString();
      var templateOrderList = _.find(mppSkusBrief, function(p) {
        return $(p).data('sku-id') === gridSkuId;
      });
      mppSkusBriefList.push(templateOrderList);
    });
    // group list by grids
    var skusSortedByGrids = _.groupBy(mppSkusBriefList, function(p) {
      return $(p).data('mpp-grid-index');
    });
    var inventoryStatusOrder = {
      '1': -1,
      '3': -1,
      '2': 2,
      '7': 3,
      '4': 4,
      '5': 4,
      '0': 4
    };
    $.each(skusSortedByGrids, function(i, skuGrid) {
      // sort by inventory status L2
      var skusSortedByInventoryL2 = _.sortBy(skuGrid, function(p) {
        var productStatus = $(p).data('inventory-status');
        return inventoryStatusOrder[productStatus];
      });
      skusBriefSorted.push(skusSortedByInventoryL2);
    });
    // add/reordered products
    $.each(skusBriefSorted, function(i, skuGrid) {
      $mppExtendedGrids.eq(i).prepend(skuGrid);
    });
    site.productView.mobileExtendedMppRebuild(skusBriefSorted);
  },

  sort: function(sortedProducts){
    var that = this;
    var $productContainer = $('.mpp__product-grid');
    if ($productContainer.length < 1) {
      return null;
    }
    var $products = $('.product-brief');

    var $inventoryGrid = $('.mpp__product-grid[data-inventory-sort]');
    if ($inventoryGrid.length > 0) {
      $(document).trigger("mpp_product_inventory_status_sort_ready_mobile", [sortedProducts]);
      return null;
    }
    if ($productContainer.length > 1) {
      this.sortMultiGrid(sortedProducts);
      return null;
    }
    //strip sorted object for PRODUCT_ID
    var productIDs = _.pluck(sortedProducts, 'PRODUCT_ID');
    var $sortedProducts = [];
    //sort by PRODUCT_ID <> data-productid
    $(productIDs).each(function(index, prodID) {
      $sortedProducts.push($products.filter("[data-product-id=" + prodID +  "]"));
    });

    // apply original tout positions after sorting products
    var toutsPlaceholder = this.getTouts($products);

    //remove styles on $products
    $products.removeAttr('style');
    //move sorted products to top
    $productContainer.prepend($sortedProducts);
    //add back in quickshop and update row heights
    if (that.allowExtendedMPPSort) {
      site.productView.mobileExtendedMppRebuild(sortedProducts);
      site.productView.mobileMppRowHeights();
    } else {
      site.productView.mobileMppRebuild(sortedProducts);
    }
    this.insertTouts(toutsPlaceholder, $sortedProducts);
  },

  getTouts: function($products) {
    var toutsPlaceholder = [];
    $products.each(function(i) {
      var $toutGrid = $(this);
      if ($toutGrid.find('.js-product-grid__tout').length > 0) {
        toutsPlaceholder.push($toutGrid);
      }
    });
    return toutsPlaceholder;
  },

  insertTouts: function(toutsPlaceholder, $sortedProducts) {
    _.each(toutsPlaceholder, function(tout, i) {
      if (tout) {
        tout.insertBefore($sortedProducts[tout.data('position') - i]);
      }
    });
  },
  setProdsByInv: function (sortedProducts) {
    this.prodsByInv = sortedProducts;
  }
};
// trigger inventory status reordering w L2
$(document).on('mpp_sku_grid_inventory_status_sort', function(e, data) {
  site.productSort.sortSkuGridByInventoryStatus(data.products);
});
